const info = {
    CN: {
        contactUs: "联系我们",
        followUs: "关注我们",
        addr: "地址：北京市朝阳区东三环北路甲19号嘉盛中心",
        tel: "电话：010-87856177",
        email: "邮箱：contact@honglingtech.com.cn",
    },
    EN: {
        contactUs: "CONTACT US",
        followUs: "FOLLOW US",
        addr: "Our Office：Jiasheng Center, No. 19A, North Road of East Third Ring, Chaoyang District, Beijing",
        tel: "TEL：010-87856177",
        email: "E-MAIL：contact@honglingtech.com.cn",
    },
};
export default info;
