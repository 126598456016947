const info = {
    CN: {
        //中文翻译
        banner: {
            title: "公司动态",
            // text:'基于物联网大数据、人工智能、机器学习等新技术，宏瓴科技在国内保险行业 User Behavior Insurance 人工智能算法领域具有显著影响力'
            text: "",
        },
        sigrealNews: [
            {
                date1: "2025年1月",
                date2: "2025年",
                title: "宏瓴科技入选“毕马威中国 KPMG Fintech 50”",
                text: "凭借近六年来在金融、交通运输领域的持续深耕，尤其是大数据和智能风控细分领域的丰硕成果，宏瓴科技入选“2024 KPMG Fintech 50 ”，这一荣誉充分彰显出业内权威机构对宏瓴科技在金融与交通运输领域以及创新性科研能力、智能风控解决方案能力的高度认可，也是对宏瓴科技未来发展潜力和行业引领地位的认可。",
                src: "dynamic-26.png",
            },
            {
                date1: "2024年12月",
                date2: "2024年",
                title: "宏瓴科技CEO入选 “2024年度杰出青年创新领袖”",
                text: "宏瓴科技创始人兼CEO入选2024第八届全球青年创新大会 “年度杰出青年创新领袖”。这是CEO继2023年度中国保险科技十年百人、2022年度创业之星、2020年度中关村U30、2022年度36氪“保持奇迹”「X·36Under36」年度S级青年创业者之后获得的又一荣誉称号，也是对宏瓴科技在新经济领域下杰出贡献的有力见证。",
                src: "dynamic-25.png",
            },
            {
                date1: "2024年12月",
                date2: "2024年",
                title: "宏瓴科技入选“2024年度最具成长力创新公司”",
                text: "宏瓴科技荣膺“2024 年度最具成长力创新公司”。宏瓴科技凭借其自主研发的全球首款端到端的AI风险量化评估模型在风险管理领域取得了领先地位。作为人工智能风险管理领域的先驱，宏瓴科技通过持续的技术创新以及研究，与多家企业和研究机构建立了深度商业模式融合。",
                src: "dynamic-24.png",
            },
            {
                date1: "2024年6月",
                date2: "2024年",
                title: "宏瓴科技获战略合作伙伴小鹏汽车颁发“AI风控创新奖”",
                text: "宏瓴科技获“AI风控创新奖”。这是继2023年4月小鹏汽车颁发“AI科技创新贡献奖”后对宏瓴科技以AI助力车险智能化专业能力的再次认可。2023年，宏瓴科技通过自主研发的AI模型实时计算识别为小鹏汽车车主提供理赔服务。未来，还将通过差异化风险定价，帮助小鹏汽车打造数智化产品运营闭环和服务运营闭环，实现客户精细化运营管理，满足产品全生命周期的用户全触点体验。",
                src: "dynamic-22.png",
            },
            {
                date1: "2024年3月",
                date2: "2024年",
                title: "2023年特色产业集群赋能典型案例-多模态数据 AI 助力交通出行领域风险减量",
                text: "宏瓴科技“多模态数据 AI 助力交通出行领域风险减量”入选典型案例。“多模态数据析AI”项目专注于AI驱动的风险评估与管理。利用大模型技术和多模态数据，提供基于用户行为的风险评估和定价，通过主动介入企业日常风险管理，降低企业风险成本。针对企业用户衍生出的新的风险管理诉求，依托新技术，结合产业升级，不断拓展风险减量服务边界。",
                src: "dynamic-23.png",
            },
            {
                date1: "2024年2月",
                date2: "2024年",
                title: "宏瓴科技登榜毕马威中国“AI与大数据”双50榜单",
                text: "上榜毕马威中国2023“AI与大数据”双50榜单。宏瓴科技利用大数据、人工智能等技术手段，通过主动介入企业日常风险管理，降低企业风险成本。依托新技术的应用，针对企业用户衍生出的新的风险管理诉求，通过与产业升级相结合从而不断拓展风险减量服务边界。",
                src: "dynamic-21.png",
            },
            {
                date1: "2024年1月",
                date2: "2024年",
                title: "宏瓴科技“全栈式风险评估量化平台”荣膺“保险+人工智能”经典案例",
                text: "“全栈式风险评估量化平台”项目成功入选保观“寻找未来：保险+人工智能实践案例征集”。“全栈式风险评估量化平台”专注于AI驱动的保险风险评估与管理。利用先进的大模型技术和多模态数据，提供基于用户行为的风险评估和定价，辅助保险公司在承保、运营和理赔各环节实现智能化风控。同时通过实时行为分析、风险案件理赔预警等功能，有效减少企业运营成本，提高保险服务的效率和精准度。",
                src: "dynamic-20.png",
            },
            {
                date1: "2023年12月",
                date2: "2023年",
                title: "宏瓴科技CEO获选“中国保险科技十年百人”",
                text: "2023 中国保险科技十年百人榜单发布，宏瓴科技创始人兼CEO入选“中国保险科技十年百人评选”榜单。作为启动并引领了新的保险科技项目的“创新者”，宏瓴科技创始人兼CEO有着丰富的精算建模、系统平台设计、AI风险管理等经验，曾荣获2022年度创业之星、2020年度中关村U30、2022年度36氪“保持奇迹”「X·36Under36」年度S级青年创业者等多项荣誉。",
                src: "dynamic-19.png",
            },
            {
                date1: "2023年11月",
                date2: "2023年",
                title: "宏瓴科技CEO受邀出席波士顿大学亚洲校友峰会",
                text: "波士顿大学在深圳举办2023年度波士顿大学亚洲校友峰会。4日，宏瓴科技创始人兼CEO作为创业者代表受邀进行企业介绍及创业分享。宏瓴科技专注于AI foundation模型技术研究，通过精细化运营实现风险减量，降低企业风险管理成本，始终致力于将AI技术深度应用服务于合作伙伴，“Transform Risk Into Return（将风险转化为回报）”。",
                src: "dynamic-18.png",
            },
            {
                date1: "2023年5月",
                date2: "2023年",
                title: "人民日报、中国工业新闻网等主流媒体对小鹏汽车战略合作伙伴宏瓴科技进行报道",
                text: "人民日报、中国工业新闻网、凤凰网、网易新闻、新浪新闻发文“宏瓴科技牵手造车新势力 智能车险时代即将来临”、““大数据＋AI”赋能保险服务，智能车险时代即将来临”，采访为小鹏汽车与８家保险公司提供智能车险技术支持的宏瓴科技。宏瓴科技作为人工智能技术领域的先行者，将针对市场和用户的需求，与保险公司、汽车主机厂共同构建智能化汽车后市场服务体系，加快推动智能保险时代的到来。",
                src: "dynamic-17.png",
            },
            {
                date1: "2023年4月",
                date2: "2023年",
                title: "宏瓴科技获战略合作伙伴小鹏汽车颁发“AI科技创新贡献奖”",
                text: "宏瓴科技与小鹏汽车在创新保险产品研发、智能理赔、风险管控等多方面展开合作。宏瓴科技在物联网大数据、车险个性化定价、理赔风控等场景拥有强大的技术优势，以物联网创新保险风控产品赋能汽车后市场保险服务的进步与提升。“AI科技创新贡献奖”称号的获得，也是合作伙伴对宏瓴科技以AI助力车险智能化专业能力的认可。",
                src: "dynamic-14.png",
            },
            {
                date1: "2023年3月",
                date2: "2023年",
                title: "宏瓴科技CEO入选“2022年度创业之星”",
                text: "宏瓴科技CEO入选 “ 2022年度创业之星 ” ，这是CEO继“ ‘保持奇迹’「X·36Under36」年度S级青年创业者 ”之后获得的又一荣誉称号，也是对宏瓴科技在技术领域深耕细作、以技术迭代产品、以产品驱动商业的运作模式的认可。",
                src: "dynamic-13.png",
            },
            {
                date1: "2022年10月",
                date2: "2022年",
                title: "宏瓴科技与太平洋财产保险成立车联网智能联合实验室",
                text: "宏瓴科技与太平洋财产保险股份有限公司“车联网智能联合实验室”在北京成立。双方协同创新，整合技术、人员、产品和系统资源，共同推进创新型数智化保险建设领域的创新应用。",
                src: "dynamic-16.png",
            },
            {
                date1: "2022年6月",
                date2: "2022年",
                title: "宏瓴科技CEO获36氪“保持奇迹”「X·36Under36」年度S级青年创业者称号",
                text: "宏瓴科技CEO荣获36氪“保持奇迹”「X·36Under36」年度S级青年创业者称号，这是宏瓴科技CEO继“创青春-中关村U30”之后获得又一荣誉称号，也是对宏瓴科技商业生态和社会效益的一种肯定。",
                src: "dynamic-12.png",
            },
            {
                date1: "2022年2月",
                date2: "2022年",
                title: "中央电视台走进宏瓴科技做“人才就业”专题采访",
                text: "宏瓴科技CEO向中央电视台记者介绍，宏瓴科技计划招聘50到100个与人工智能、大数据等相关的岗位人员。为吸引人才，宏瓴科技诚意十足，对求职者开出业内极有竞争力的薪资待遇。此次采访于CCTV2《经济信息联播》、《天下财经》、《正点财经》等多档栏目播出。",
                src: "dynamic-11.png",
            },
            {
                date1: "2022年1月",
                date2: "2022年",
                title: "宏瓴科技与华农保险成立车联网数字化运营联合实验室",
                text: "宏瓴科技与华农保险“车联网数字化运营联合实验室”在北京正式挂牌成立。车联网数字化运营联合实验室的成立，是在 User Behavior Insurance 、车联网、保险与科技互相融合新态势下的一次重要合作，也是基于人工智能打造保险企业数字化产品落地样板间的一次强强联合。",
                src: "dynamic-10.png",
            },
            {
                date1: "2022年1月",
                date2: "2022年",
                title: "宏瓴科技获选北京市2022年度第一批认定“专精特新”科技型企业",
                text: "宏瓴科荣获北京市“专精特新”科技型企业称号。本次入选是宏瓴科技向“专、精、特、新”稳步迈进的里程碑。未来，宏瓴科技将持续投入人工智能研发，构建适用于保险科技、大出行等业务场景的机器学习算法，不断推动技术成果的应用和转化升级。",
                src: "dynamic-9.png",
            },
            {
                date1: "2021年12月",
                date2: "2021年",
                title: "宏瓴科技荣登“2021毕马威中国领先金融科技50企业”榜单",
                text: "宏瓴科技成功上榜毕马威中国“2021领先金融科技50企业”。宏瓴科技的入选，显示了自身在运用人工智能、深度学习、物联网、大数据、云计算等技术赋能保险行业转型升级、降本增效、服务实体经济发展等方面得到了业界的广泛认可。",
                src: "dynamic-8.png",
            },
            {
                date1: "2021年9月",
                date2: "2021年",
                title: "宏瓴科技受邀出席再保险（中国）市场研讨会",
                text: "宏瓴科技CEO以专家身份受邀在“太平再保险（中国）2021年市场研讨会”分享 User Behavior Insurance 保险及新能源汽车后市场发展相关内容。此次研讨会有中国人民保险、太平洋保险、平安保险、中国人寿财产保险、中华联合保险等50多家保险公司再保部和业务条线等相关部门领导参加。",
                src: "dynamic-7.png",
            },
            {
                date1: "2021年9月",
                date2: "2021年",
                title: "新能源汽车智能保险开启新时代：宏瓴科技与高合汽车达成战略合作",
                text: "9月中旬，宏瓴科技与高合汽车就新能源 User Behavior Insurance 汽车保险创新签署战略协议，这是国内保险科技公司和汽车品牌在保险创新领域的一次里程碑式合作，此次合作必将开启新能源汽车智能保险创新的新时代，为全行业打造保险科技公司与豪华智能纯电品牌合作共创的示范样板。",
                src: "dynamic-6.png",
            },
            {
                date1: "2021年7月",
                date2: "2021年",
                title: "宏瓴科技荣获“2021年度保险科技创新优秀奖”",
                text: "由分子实验室发起、瑞士再保险联合主办的“2021年度保险科技创新大赛”落下帷幕，宏瓴科技获“2021年度保险科技创新优秀奖”和“2021中国保险科技100强”。这是宏瓴科技继InsurStar保险科技新锐奖Top25（暨专注于 User Behavior Insurance 的保险科技上榜企业）后获得的又一行业知名奖项。",
                src: "dynamic-15.png",
            },
            {
                date1: "2020年12月",
                date2: "2020年",
                title: "宏瓴科技荣获「2020 InsurStar保险科技新锐奖」",
                text: "宏瓴科技成为“2020 InsurStar保险科技新锐奖TOP25”中上榜的专注于 User Behavior Insurance 的保险科技企业。",
                src: "dynamic-2.png",
            },
            {
                date1: "2020年11月",
                date2: "2020年",
                title: "宏瓴科技在团中央举办的青年创新创业大赛获银奖",
                text: "宏瓴科技以“基于人工智能、物联网大数据在汽车后市场的应用”项目获共青团中央、江西省人民政府主办的第七届“创青春”中国青年创新创业大赛（互联网组）全国赛银奖。",
                src: "dynamic-3.png",
            },
            {
                date1: "2020年9月",
                date2: "2020年",
                title: "2020年中关村U30年度优胜者",
                text: "宏瓴科技CEO入选中关村、共青团中央“创青春-U30”2020年度优胜者。获奖照片曾于东单地铁站展示。",
                src: "dynamic-4.png",
            },
            {
                date1: "2020年8月",
                date2: "2020年",
                title: "宏瓴科技在Plug and Play China中国创新生态大会获得最具潜力奖",
                text: "宏瓴科技CEO受邀参加2020年Plug and Play China中国创新生态大会。本次活动宏瓴科技在移动出行和保险科技两个论坛中分别进行了路演展示。基于过去一年以及疫情期间的努力，宏瓴科技获得主办方Plug and Play China颁发的“最具潜力奖”。",
                src: "dynamic-5.png",
            },
        ],
    },
    EN: {
        //英文翻译
        banner: {
            title: "SiGREAL News",
            text: "Based on cutting-edge technologies, our User Behavior Insurance algorithms have already had an impact on the domestic insurance industry.",
        },
        sigrealNews: [
            {
                date1: "Jan. 2025",
                date2: "2025",
                title: `SiGREAL Tech Selected for "KPMG China Fintech 50"`,
                text: `With nearly six years of continuous dedication in the financial and transportation sectors, particularly in the subfield of big data and intelligent risk control, SiGREAL Tech has been selected for the "2024 KPMG Fintech 50." This recognition fully reflects the industry's authoritative acknowledgment of SiGREAL Tech's significant achievements in financial and transportation sectors, as well as its innovative research capabilities and expertise in intelligent risk control solutions. It also serves as a recognition of SiGREAL Tech's potential for future development and its leading position in the industry.`,
                src: "dynamic-26.png",
            },
            {
                date1: "Jun. 2024",
                date2: "2024",
                title: `The CEO of SiGREAL Tech has been selected for the "2024 Outstanding Young Innovative Leader" award.`,
                text: `The founder and CEO of SiGREAL Tech has been selected for the "2024 Outstanding Young Innovative Leader" award at the 8th Global Youth Innovation Conference. This accolade follows the CEO's previous recognitions as one of the "Top 100 in China's Insurance Technology Over the Past Decade" in 2023, the "Entrepreneur of the Year" in 2022, the "Zhongguancun U30" in 2020, and the "X·36Under36" S-level young entrepreneur in 2022 by 36Kr's "Keeping the Miracle." This latest honor serves as a powerful testament to SiGREAL Tech's outstanding contributions in the new economy sector.`,
                src: "dynamic-25.png",
            },
            {
                date1: "Jun. 2024",
                date2: "2024",
                title: `SiGREAL Tech has been selected as one of the "Most Promising Innovative Companies of 2024."`,
                text: `SiGREAL Tech has been honored with the title of "Most Promising Innovative Company of 2024." The company has established a leading position in the field of risk management through its independently developed, global-first end-to-end AI risk quantification and assessment model. As a pioneer in the realm of artificial intelligence-driven risk management, SiGREAL Tech has achieved profound business model integration by continuously innovating and conducting research in collaboration with multiple enterprises and research institutions.`,
                src: "dynamic-24.png",
            },
            {
                date1: "Jun. 2024",
                date2: "2024",
                title: `SiGREAL Tech CEO Awarded '2024 Outstanding Young Innovative Leader'`,
                text: `The founder and CEO of SiGREAL Tech has been named the recipient of the "2024 Outstanding Young Innovative Leader" award at the 8th Global Youth Innovation Conference. This recognition adds to a series of accolades the CEO has received, including being listed among the "Top 100 in China's Insurance Technology Over the Past Decade" in 2023, named "Entrepreneur of the Year" in 2022, and honored as part of "Zhongguancun U30" in 2020. Additionally, in 2022, the CEO was recognized as an S-level young entrepreneur in 36Kr's "X·36Under36" program. These achievements highlight SiGREAL Tech’s contributions to innovation and its leadership in the new economy sector.`,
                src: "dynamic-22.png",
            },
            {
                date1: "Mar. 2024",
                date2: "2024",
                title: `SiGREAL Tech Recognized as One of the 'Most Promising Innovative Companies of 2024'`,
                text: `SiGREAL Tech has been recognized as one of the "Most Promising Innovative Companies of 2024." The company stands out for its advancements in risk management, particularly with the development of an end-to-end AI-powered risk quantification and assessment model, which is among the first of its kind globally. As a leader in AI-driven risk management, SiGREAL Tech has fostered innovation through close collaboration with enterprises and research institutions, driving the integration of advanced technology into business models. This recognition underscores the company's commitment to reshaping the field of risk management with cutting-edge solutions.`,
                src: "dynamic-23.png",
            },
            {
                date1: "Feb. 2024",
                date2: "2024",
                title: `SiGReal Tech listed on the KPMG China-Fintech-50-2023 "AI and Big Data" List`,
                text: `SiGReal Tech was listed on the KPMG China-Fintech-50-2023 "AI and Big Data" List.SiGReal Tech utilizes technologies such as big data and AI to actively engage in enterprise daily risk management to reduce enterprise risk costs. By leveraging the application of new technologies and addressing the new risk management demands of enterprise, SiGReal Tech continuously expands the boundaries of risk reduction services through integration with industry upgrades.`,
                src: "dynamic-21.png",
            },
            {
                date1: "Jan. 2024",
                date2: "2024",
                title: `SiGReal Tech's "Full-stack Risk Assessment Quantitative Platform" selected for the "Insurance + AI" classic case`,
                text: `"Full-stack Risk Assessment Quantitative Platform" project has been successfully selected for the "Seeking the Future: Insurance + Artificial Intelligence Practice Case Collection" by BaoGuan. The Platform focuses on AI-driven insurance risk assessment and management. By utilizing advanced large-scale modeling technology and multimodal data, it provides user behavior-based risk assessment and pricing, assisting insurance companies in achieving intelligent risk control in underwriting, operations, and claims. At the same time, through real-time behavior analysis, risk case claims warning, and other functions, it effectively reduces enterprise operating costs and improves the efficiency and accuracy of insurance services.`,
                src: "dynamic-20.png",
            },
            {
                date1: "Dec. 2023",
                date2: "2023",
                title: `SiGReal Tech CEO  selected for the  "China Insurance Technology Ten-Year Hundred People"`,
                text: `SiGReal Tech CEO was selected for the"China Insurance Technology Ten-Year Hundred People List" which was released on December 28th, 2023。As an "innovator" who initiated and led new insurance technology projects, SiGReal Tech CEO has rich experience in actuarial modeling, system platform design, AI risk management，who has won multiple honors such as "2022 Entrepreneurial Star"，“the 2020 Zhongguancun U30” , and “S class Young Entrepreneur of 36Kr “Keeping the Miracle” 「X·36Under36」”.`,
                src: "dynamic-19.png",
            },
            {
                date1: "Nov. 2023",
                date2: "2023",
                title: `The CEO of SiGReal Tech was invited to attend the Boston University Asian Alumni Forum`,
                text: `Boston University held the 2023 Boston University Asia Alumni Forum from November 3rd to 5th in Shenzhen，China. On the 4th, the CEO of SiGReal Tech was invited as a representative of entrepreneurs to share entrepreneurial experiences.SiGReal Tech focuses on AI foundation models technology research and achieves risk reduction through refined operations, reduces enterprise risk management costs. SiGReal Tech commits to deeply applying AI technology to serve partners and "Transform Risk Into Return".`,
                src: "dynamic-18.png",
            },
            {
                date1: "May. 2023",
                date2: "2023",
                title: `Mainstream media outlets such as People's Daily and China Industry News have covered the intelligent claims technology supplier, SiGReal Tech, for Xiaopeng Motors`,
                text: `Prominent media outlets such as People's Daily, China Industrial News, Phoenix News, NetEase News, and Sina News have featured articles like 'SiGReal Tech Partners with Emerging Automotive Forces, Paving the Way for the Era of Intelligent Auto Insurance' and 'Empowering Insurance Services with Big Data and AI, the Era of Intelligent Auto Insurance is Just Around the Corner.' These articles highlighted SiGReal Tech's role in providing Intelligent Auto Insurance technology support to Xpeng Motors and eight insurance companies. As a pioneer in the field of artificial intelligence technology, Sigreak Tech aims to cater to market and user demands by collaborating with insurance companies and automobile manufacturers, fostering the development of an intelligent automotive aftermarket service system, and expediting the advent of the intelligent insurance era.`,
                src: "dynamic-17.png",
            },
            {
                date1: "Apr. 2023",
                date2: "2023",
                title: 'SiGReal Tech received the "AI Technology Innovation Contribution Award" from XPeng Motors',
                text: `SiGReal Tech and XPeng have collaborated on various aspects, such as the research and development of innovative insurance products, intelligent claims processing, and risk management. SiGReal Tech possesses strong technical advantages in areas like IoT big data, personalized auto insurance pricing, and claims risk control. By leveraging IoT, they have developed innovative insurance risk control products to empower the progress and improvement of the automotive aftermarket insurance services. The "AI Technology Innovation Contribution Award" acknowledges SiGReal Tech's expertise in using AI to facilitate the intelligence of auto insurance services.`,
                src: "dynamic-14.png",
            },
            {
                date1: "Mar. 2023",
                date2: "2023",
                title: 'The CEO of SiGReal Tech was selected as the "2022 Entrepreneurial Star"',
                text: `The CEO of SiGReal Tech was selected as the "2022 Entrepreneurial Star", which is another honorary title obtained by the CEO after winning the honour of " ‘Keeping the Miracle’「X·36Under36」annual S-level young entrepreneurs " and also a recognition of Hongling Technology's operation mode of specializing in-depth in the field of technology, iterating products with technology, and driving business with products.`,
                src: "dynamic-13.png",
            },
            {
                date1: "Oct. 2022",
                date2: "2022",
                title: "SiGReal Tech and China Pacific Property Insurance have joined hands to establish the Intelligent Joint Laboratory of Internet of Vehicles",
                text: `The 'Intelligent Joint Laboratory of Internet of Vehicles,' jointly established by SiGReal Tech and China Pacific Property Insurance, is located in Beijing. The two parties collaborate on innovation, integrating technology, personnel, products, and system resources to collectively drive innovative applications in the field of data-intelligent insurance construction.`,
                src: "dynamic-16.png",
            },
            {
                date1: "Jun. 2022",
                date2: "2022",
                title: "The CEO of SiGReal Tech was awarded as S class Young Entrepreneur of 36Kr “Keeping the Miracle” 「X·36Under36」",
                text: `It is another award following the "2020 Zhongguancun U30", and it is also an affirmation of Sigreal's business ecology and social benefits.`,
                src: "dynamic-12.png",
            },
            {
                date1: "Feb. 2022",
                date2: "2022",
                title: "CCTV interviewed SiGReal Tech on employment",
                text: "The CEO of SiGReal Tech introduced to CCTV reporters that we planned to recruit 50 to 100 talents in the field of AI, big data, or some other positions. In order to attract talents, we offered a very competitive salary package for job seekers. The interview was broadcast on lots of CCTV2's programs.",
                src: "dynamic-11.png",
            },
            {
                date1: "Jan. 2022",
                date2: "2022",
                title: 'SiGReal Tech set up a "Joint Lab of IoV Digital Operation" to cooperate with CHIC (China Huanong Property & Casualty Insurance Co., Ltd.)',
                text: "This is an important cooperation under the new fusion of User Behavior Insurance, telematics, and insurance. It’s also a demonstration project to create digital products for insurance companies based on AI.",
                src: "dynamic-10.png",
            },
            {
                date1: "Jan. 2022",
                date2: "2022",
                title: 'SiGReal Tech was awarded the first batch of "Specialized, Refinement, Differentiation and Innovative" technology enterprises in 2022 in Beijing',
                text: 'This is a milestone for us to become a member of "Specialized, Refinement, Differentiation and Innovative" enterprises. We will continue to invest in AI research, and promote the application, transformation, and innovation of technological achievements.',
                src: "dynamic-9.png",
            },
            {
                date1: "Dec. 2021",
                date2: "2021",
                title: `SiGReal Tech was listed among "2021 KPMG's Top 50 Financial and High-Tech Enterprises in China"`,
                text: "This shows that we have had an impact on the User Behavior Insurance profession. Our mission is to help insurance companies upgrade in the AI era by cutting-edge deep learning technology.",
                src: "dynamic-8.png",
            },
            {
                date1: "Sep. 2021",
                date2: "2021",
                title: "SiGReal Tech was invited to attend Taiping Reinsurance (China) Market Seminar",
                text: 'The CEO of SiGReal Tech was invited as an expert to share the relevant experience of User Behavior Insurance and new energy vehicles aftermarket development in "Taiping Reinsurance (China) 2021 Market Seminar". SiGReal Tech has its voice in the reinsurance industry.',
                src: "dynamic-7.png",
            },
            {
                date1: "Sep. 2021",
                date2: "2021",
                title: "SiGReal Tech reached strategic cooperation with HiPhi: An intelligent insurance age is coming",
                text: "This cooperation will open a new era of intelligent insurance innovation for new energy vehicles, and create a model for the cooperation between insurance technology companies and luxury intelligent electronic brands.",
                src: "dynamic-6.png",
            },
            {
                date1: "July 2021",
                date2: "2021",
                title: 'SiGReal Tech was awarded in the "2021 Insurance Innovation Competition"',
                text: 'The "2021 Insurance Innovation Competition" initiated by molecular laboratory and cosponsored by Swiss Reinsurance is another industry-renowned award following the "Top 25 New Prominent InsurTech Award of 2020".',
                src: "dynamic-15.png",
            },
            {
                date1: "Dec. 2020",
                date2: "2020",
                title: 'SiGReal Tech was awarded the "Top 25 New Prominent InsurTech Award of 2020"',
                text: 'SiGReal Tech became the only one to focus on User Behavior Insurance in the "Top 25 New Prominent InsurTech Award of 2020".',
                src: "dynamic-2.png",
            },
            {
                date1: "Nov. 2020",
                date2: "2020",
                title: 'SiGReal Tech won the silver medal of the "Youth Innovation and Entrepreneurship Competition"',
                text: `This activity was hosted by the Central Committee of the Communist Youth League and the People's Government of Jiangxi Province. We were awarded the silver medal for the application in the automotive aftermarket with AI and the Internet of telematics data.`,
                src: "dynamic-3.png",
            },
            {
                date1: "Sep. 2020",
                date2: "2020",
                title: 'SiGReal Tech was the winner of "2020 Zhongguancun U30"',
                text: 'The CEO of SiGReal Tech was selected as the winner of "2020 Create Youth-U30" by Zhongguancun and the Central Committee of the Communist Youth League.',
                src: "dynamic-4.png",
            },
            {
                date1: "Aug. 2020",
                date2: "2020",
                title: 'SiGReal Tech won the most potential award in the "Plug and Play China Innovation Ecology Conference"',
                text: 'The CEO of SiGReal Tech was invited to attend the "2020 Plug and Play China Innovation Ecology Conference". SiGReal Tech conducted roadshows in the mobile travel and insurance technology forums respectively in this event. SiGReal Tech won the most potential award by the "Plug and Play China" based on our persistence and diligence throughout the Covid-19 pandemic.',
                src: "dynamic-5.png",
            },
        ],
    },
};
export default info; //需要暴露出去
