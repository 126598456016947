const info = {
    CN: {
        //中文翻译
        postDetails: [
            {
                id: "lx1",
                name: "瓴先人才计划",
                duty: ["", "", ""],
                request: ["", "", ""],
            },
            {
                id: "sz1",
                name: "首席架构师",
                duty: [
                    "1、带领技术团队研发并实施，负责系统总体设计与核心代码开发：应用系统架构设计、开发框架搭建、开发规范制定，核心算法编写，指导开发团队进行业务模块代码编写等。",
                    "2、负责优化现有产品架构，设计新的架构，解决现有技术架构的瓶颈，解决现有业务产品优化升级的技术问题等。",
                    "3、负责产品的创新迭代，站在业务角度负责信息技术应用与创新，产品升级迭代，解决技术难点，使技术设计方案落地。",
                    "4、负责现有产品重构设计、功能改进、技术升级。",
                ],
                request: [
                    "1、硕士以上学历， 10年及以上开发经验，掌握数据库理论、设计方法等，有丰富的高并发、微服务构建经验。",
                    "2、精通 Java 语言，熟练掌握Spring、Springboot、mybatis框架，有Spring Cloud框架经验优先。",
                    "3、熟悉底层中间件，分布式技术（如负载均衡、RPC框架、缓存、消息系统等）有 netty， MQTT，处理高并发系统经验优先。",
                    "4、对数据清洗、处理、数仓建设有经验者优先，有 Flink、 Hive、 Spark、 Kafka、 Hbase 等大数据技术经验者优先。",
                    "5、有车联网或 JT808 协议解析经验优先；有大型车联网平台及微服务相关架构经验者优先；有移动端开发经验或移动应用后端开发经验优先。",
                    "6、较强的性能优化及问题排查、解决能力，认真负责，有良好的沟通和团队协作能力。",
                ],
            },
            {
                id: "sz2",
                name: "算法工程师",
                duty: [
                    "1、公司业务相关AI能力开发与应用，探索前沿深度学习/机器学习方法的边界，包括但不限于深度神经网络，图神经网络，时间序列模型，监督和自监督等学习算法。",
                    "2、与团队紧密合作进行模型效果和效率的优化，并配合将模型部署到生产环境下，支持产品快速落地。",
                    "3、深入业务场景，持续关注人工智能领域的最新动态和进展，不断迭代与创新。",
                ],
                request: [
                    "1、计算机/电子信息/自动化/数学/物理/统计等相关专业，硕士及以上学历。",
                    "2、数学基础扎实，对深度学习、机器学习的原理和主流算法有比较清晰的理解。",
                    "3、熟悉linux开发环境，熟练掌握Python环境下快速的算法实现，熟悉至少一种深度学习框架，PyTorch，Tensorflow，Caffe，etc。",
                    "4、有深度学习、机器学习产品级应用经验者优先。",
                    "5、积极乐观，热爱挑战，不循规蹈矩者优先。",
                    "6、有以下至少某一方面的背景：计算机视觉（CV）、自然语言处理（NLP）、 语音识别、机器学习及金融领域算法应用。",
                ],
            },
            {
                id: "sz3",
                name: "AI产品经理",
                duty: [
                    "1、参与公司人工智能相关产品规划，深入业务场景，挖掘业务痛点。",
                    "2、结合最新AI技术做产品规划，设计及原型输出。",
                    "3、独立负责一条业务线的产品工作，能协调跨部门合作，如协同业务、算法、研发等，推进项目进程。",
                    "4、对最新的AI模型及技术能力有深度理解，并能应用于业务场景中，不断推动公司业务人工智能化进程。",
                ],
                request: [
                    "1、本科及以上学历，3年以上AI方向产品经理工作经验。",
                    "2、有0-1产品规划和设计经验者优先，风控经验优先。",
                    "3、熟悉机器学习、知识图谱等AI技术的应用，有AI类产品实践经验。",
                    `4、敏锐的业务洞察力，能基于业务理解和AI技术特点，给出创造性解决方案。`,
                    "5、具有较强的组织、沟通、协调及抗压能力，适应创业公司高强度工作节奏。",
                ],
            },
            {
                id: "sz5",
                name: "商务总监",
                duty: [
                    "1、面向汽车主机厂、网约车平台、物流配送平台公司以及其他移动出行领域拓展业务。",
                    "2、根据公司核心业务，积极探索并拓展人工智能在业务场景中的应用。",
                    "3、深度理解公司产品在B端用户需求，推进商务合作。",
                    "4、完成重要商务洽谈以及商务合作计划的制定，并对商务结果负责。",
                    "5、对产品、内容、场景、用户有深刻理解，有独立判断能力，通过对商业模式及行业研究分析，对项目进行评估。",
                ],
                request: [
                    "1、统招本科及以上学历，5年以上商务拓展经验；有保司车险运作经验、网约车、物流、主机厂资源者优先。",
                    "2、具有丰富的商务谈判及项目管理经验，具有较强的沟通和协调能力。",
                    "3、具有敏锐的市场嗅觉、业务分析、应变创新能力和解决问题的能力。",
                    "4、具备战略思维意识、乐观积极，善于学习并有独到见解，抗压能力强。",
                    "5、有强烈的责任感和事业心，有信仰有追求，有很强的开拓创新精神和挑战欲望。",
                ],
            },
            {
                id: "xz1",
                name: "机器学习算法",
                duty: [
                    "1、探索前沿深度学习/机器学习方法的边界。研究和设计深度模型，图卷积神经网络，以及神经结构搜索算法，根据行车数据对用户驾驶行为进行精准的预测和分析，从而为大规模的车联网数据应用以及相关保险产品设计提供核心技术支持。",
                    "2、与算法工程师配合优化模型运行效率，与数据工程师配合将模型部署到通用的分布式环境下，支持产品快速落地。",
                    "3、理解产品需求，根据应用场景对算法进行优化，调整和迭代。",
                ],
                request: [
                    "1、计算机/数学/物理/统计等相关专业，硕士应届或博士在读，数学基础扎实，对深度学习、机器学习的原理和主流算法有比较清晰的理解。",
                    "2、熟悉linux开发环境，熟练掌握Python环境下快速的算法实现，熟悉至少一种深度学习框架，PyTorch,Tensorflow,Caffe 等。",
                    `3、有深度学习、机器学习产品级应用经验者优先，或有以下经验：<br/>
						&emsp;&ensp;a.计算机视觉（CV）：图像/物体识别，目标检测/分割，视频分析，医学图像处理等。<br/>
						&emsp;&ensp;b. 自然语言处理（NLP）：机器翻译，语义分析，知识图谱，智能对话、问答，（广告）文本分类、聚类，推荐系统，用户画像等。<br/>
						&emsp;&ensp;c.语音识别：智能语音识别、合成、交互，语音助手等。<br/>
						&emsp;&ensp;d.金融领域：用机器学习/深度学习做自动交易，量化投资，风险管理和反欺诈，精算建模等。
					`,
                    "4、积极乐观，热爱挑战，不循规蹈矩者优先。",
                ],
            },
            {
                id: "xz2",
                name: "管培生",
                duty: [
                    "1、入职后将参与部门轮岗，可在相关条线内部充分轮岗学习。",
                    "2、轮岗结束后，可根据个人的发展意向和部门岗位需求双向选择，进行定岗。",
                    "3、专属的培养方案，持续关注管培生的成长。",
                ],
                request: [
                    "1、985/211院校统招本科及以上学历，2年内应届毕业生，专业不限。",
                    "2、熟练使用Office等办公软件。",
                    "3、品学兼优、积极向上、对自己有良好的职业发展愿景。",
                    "4、具备较强的学习能力及优秀的沟通协调能力，善于团队合作。",
                    "5、乐于接受挑战，具备优秀的抗压能力，较强的吃苦耐劳精神。",
                    "6、热爱新科技，拥抱创新，不惧变革的年轻人。 ",
                ],
            },
        ],
    },
    EN: {
        //英文翻译
        postDetails: [],
    },
};
export default info; //需要暴露出去
